body {
    background-color: lightgray;
}

.icon-site-upwork-com {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../front/img/sites/icons/upwork-com.png) center center;
    background-size: 100%;
    margin: -2px 6px 0 0;
}

.icon-site-freelancer-com {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../front/img/sites/icons/freelancer-com.png) center center;
    background-size: 100%;
    margin: -2px 6px 0 0;
}

.icon-site-guru-com {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../front/img/sites/icons/guru-com.png) center center;
    background-size: 100%;
    margin: -2px 6px 0 0;
}

.icon-site-peopleperhour-com {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../front/img/sites/icons/peopleperhour-com.png) center center;
    background-size: 100%;
    margin: -2px 6px 0 0;
}

.calendly-spinner {
    display: none;
}
